import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL + '/back',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to inject the token
axiosInstance.interceptors.request.use(
  (config) => {
    // const token = store.getState().user.jwt; // Get the token from the state
    // const token = Cookies.get('jwt');
    const token = localStorage.getItem('authToken');
    if (token)
      config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
