import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';

const Login = lazy(() => import('./pages/Login'));
const Logout = lazy(() => import('./pages/Logout'));
const Enrollments = lazy(() => import('./pages/Enrollments'));
const Authentication = lazy(() => import('./pages/Authentication'));

const Error401 = lazy(() => import('./error/401'));
const Error403 = lazy(() => import('./error/403'));
const Error404 = lazy(() => import('./error/404'));
const Error500 = lazy(() => import('./error/500'));

class AppRoutes extends Component {
  render (props) {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/" component={ Login } />
          <Route exact path="/login" component={ Login } />
          <Route exact path="/logout" component={ Logout } />
          <Route exact path="/enrollments" component={ Enrollments } />
          <Route exact path="/authentication" component={ Authentication } />
          <Route path="/401" component={ Error401 } />
          <Route path="/403" component={ Error403 } />
          <Route path="/404" component={ Error404 } />
          <Route path="/500" component={ Error500 } />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;