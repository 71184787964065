import { configureStore } from '@reduxjs/toolkit'
import sidebarReducer from './reducers/sidebar'
import enrollmentsReducer from './reducers/enrollments'
import modalReducer from './reducers/modal'
import themeReducer from './reducers/theme'
import userReducer from './reducers/user'
import { authMiddleware } from './api/authMiddleware';


export default configureStore({
  reducer: {
    sidebar: sidebarReducer,
    enrollments: enrollmentsReducer,
    modal: modalReducer,
    theme: themeReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authMiddleware),
})