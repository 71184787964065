import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../api/axiosInstance';

export const fetchMe = createAsyncThunk('users/me', async () => {
  const response = await axiosInstance.get('/users/me');
  return response.data;
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    jwt: localStorage.getItem('authToken'),
    firstname: null,
    lastname: null,
    permissions: {
      future: false,
      futureAdmin: false,
      destination: false,
      reenroll: false,
    },
  },
  reducers: {
    setJWT: (state, action) => {
      state.jwt = action.payload;
      // Store the token in localStorage
      localStorage.setItem('authToken', action.payload);
    },
    unsetJWT: (state) => {
      state.jwt = null;
      // Remove the token from localStorage
      localStorage.removeItem('authToken');
    },
    setFirstname: (state, action) => {
      state.firstname = action.payload;
    },
    setLastname: (state, action) => {
      state.lastname = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMe.pending, (state) => {
      })
      .addCase(fetchMe.fulfilled, (state, action) => {
        state.firstname = action.payload.user.login.split('@')[0].split('.')[0];
        state.firstname = state.firstname.charAt(0).toUpperCase() + state.firstname.slice(1);
        state.lastname = action.payload.user.login.split('@')[0].split('.')[1];
        state.lastname = state.lastname.charAt(0).toUpperCase() + state.lastname.slice(1);
        state.permissions.future = action.payload.permissions.future;
        state.permissions.futureAdmin = action.payload.permissions.futureAdmin;
        state.permissions.destination = action.payload.permissions.destination;
        state.permissions.reenroll = action.payload.permissions.reenroll;
      })
      .addCase(fetchMe.rejected, (state) => {
      });
  },
})

// Action creators are generated for each case reducer function
export const {
  setJWT,
  unsetJWT,
  setFirstname,
  setLastname,
} = userSlice.actions

export default userSlice.reducer