import { useHistory } from 'react-router-dom';
import { unsetJWT } from '../reducers/user';

export const authMiddleware = store => next => action => {
    if (action.type.endsWith('rejected') && (action.error.message === 'Request failed with status code 401')) {
        store.dispatch(unsetJWT());
        const history = useHistory();
        history.push('/login');
    }

    return next(action);
};
