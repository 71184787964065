import React from 'react';
import { useSelector } from 'react-redux';

function listOptions(values) {
  if (values === undefined || values === null || values.length === 0)
    return <option>Chargement...</option>;
    // return { label: 'Chargement...', value: '' };
  let valuesCopy = values.slice();
  if (valuesCopy.length >= 1)
    valuesCopy.unshift('*void*');
  // if (((valuesCopy.length > 1) && (valuesCopy[0] !== '') && (valuesCopy[0] !== null)) || (valuesCopy[0] === '*empty*'))
  //   valuesCopy.unshift('');
  return valuesCopy.map((value, index) => {
    if ((value === undefined) || (value === null))
      return null;
      // return { label: '', value: '' };
    let valueText = value;
    if (value === true) valueText = 'Oui';
    if (value === false) valueText = 'Non';
    if (value === '') value = '*empty*';
    if (value === '*empty*') valueText = 'Vide';
    if (value === '*void*') {
      value = '';
      valueText = '';
    }
    // return { label: valueText, value: value };
    return <option key={index} value={value}>{valueText}</option>;
  });
}

function Filter({ id, name, label, onChange }) {

  let disabled = useSelector((state) => state.enrollments.filtersDisabled);
  let values = useSelector((state) => state.enrollments.filters[name].values);
  let selected = useSelector((state) => state.enrollments.filters[name].selected);

  // function handleOnChange(event) {
  //   console.log(event.target.value);
  //   onChange?.(event);
  // }

  // function handleOnChange(item) {
  //   // if (!item || item.value === selected)
  //   //   return;
  //   onChange({ 
  //     target: {
  //       name: name,
  //       value: item?.value || '',
  //     },
  //   });
  //   return { label: item?.label };
  // }
  
  // if (selected !== '') {
  //   onChange({ 
  //     target: {
  //       name: name,
  //       value: selected,
  //     },
  //   });
  // }

  return (
    <li className="nav-item">
      <div className="form-group">
        <label htmlFor={id}>{label}</label>
        {/* <Select
          className="form-control"
          options={options}
          onChange={handleOnChange}
          id={id}
          disabled={disabled}
          isClearable={true}
          isSearchable={true}
          value={{label: selected}}
        /> */}
        <select className="form-control" id={id} name={name} disabled={disabled} onChange={onChange} value={selected}>
          {listOptions(values)}
        </select>
      </div>
    </li>
  );
}
export default Filter;