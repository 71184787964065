import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import '../assets/styles/dark.scss';
import '../assets/styles/light.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function App(props) {
  
  const jwt = useSelector((state) => state.user.jwt);
  const [isFullPageLayout, setIsFullPageLayout] = useState(true);
  const [pathname, setPathname] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  let navbarComponent = !isFullPageLayout ? <Navbar /> : '';
  let sidebarComponent = !isFullPageLayout ? <Sidebar /> : '';
  let footerComponent = !isFullPageLayout ? <Footer /> : '';
  
  const history = useHistory(); // Get the history object to programmatically navigate

  useEffect(() => {
    if (props.location.pathname !== pathname) {
      setPathname(props.location.pathname);
      onRouteChanged();
    }
  }, [pathname, setPathname, onRouteChanged, props.location.pathname]);

  useEffect(() => {
    if (isAuthenticated === false) {
      if (jwt !== null) {
        setIsAuthenticated(true);
        if ((props.location.pathname === '/') || (props.location.pathname === '/login') || (props.location.pathname === '/authentication')) {
          history.push('/enrollments');
        }
      } else if ((props.location.pathname !== '/') && (props.location.pathname !== '/login') && (props.location.pathname !== '/logout') && (props.location.pathname !== '/authentication')) {
        history.push('/');
      }
    } else {
      if (jwt === null) {
        setIsAuthenticated(false);
        history.push('/');
      }
    }
    if ((jwt === null) && (isAuthenticated === true)) {
      setIsAuthenticated(false);
      history.push('/');
    }
  }, [history, isAuthenticated, setIsAuthenticated, jwt]);
  // }, [pathname, setPathname, onRouteChanged, props.location.pathname, history, isAuthenticated, setIsAuthenticated, jwt]);

  function onRouteChanged() {
    const { i18n } = props;
    const body = document.querySelector('body');
    if(props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/', '/login', '/logout', '/authentication'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (props.location.pathname === fullPageLayoutRoutes[i]) {
        setIsFullPageLayout(true);
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        setIsFullPageLayout(false);
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

  return (
    <div className="container-scroller">
      { sidebarComponent }
      <div className="container-fluid page-body-wrapper">
        { navbarComponent }
        <AppRoutes/>
        { footerComponent }
        </div>
    </div>
  );

}

export default withTranslation()(withRouter(App));
