import React, { Component } from 'react';
// import { Trans } from 'react-i18next';
class Footer extends Component {
  render () {
    const currentYear = new Date().getFullYear();
    return (
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
            Copyright &copy; Epitech {currentYear} - Tous droits réservés
          </span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Made with &lt;3 by DDOS</span>
        </div>
      </footer>
    );
  }
}

export default Footer;