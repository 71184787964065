import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiMenu, mdiViewGrid, mdiAccountMultiple, mdiAccountOutline, mdiKeyVariant, mdiServerNetwork, mdiLogout, mdiMenuDown } from '@mdi/js';
import { toggleDarkMode } from '../reducers/theme';
import { fetchMe } from '../reducers/user';
import { fetchEnrollment, resetApiResponse } from '../reducers/enrollments';

function Navbar() {

  const darkMode = useSelector((state) => state.theme.darkMode);
  const firstname = useSelector((state) => state.user.firstname);
  const lastname = useSelector((state) => state.user.lastname);
  const enrollment = useSelector((state) => state.enrollments.enrollment);
  const enrollmentApiResponse = useSelector((state) => state.enrollments.apiResponse);

  const [toastMessage, setToastMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (darkMode)
      document.documentElement.setAttribute("data-theme", "dark");
    else
      document.documentElement.setAttribute("data-theme", "light");
  }, [darkMode]);

  useEffect(() => {
    if (enrollmentApiResponse.status !== null) {
      if (enrollmentApiResponse.thunk === 'updateFuturePedagoStatus') {
        if (enrollmentApiResponse.status === 'success') {
          setToastMessage('Le devenir a bien été mis à jour');
        } else {
          setToastMessage('Une erreur est survenue lors de la mise à jour du devenir');
        }
      } else if (enrollmentApiResponse.thunk === 'updateFutureDestination') {
        if (enrollmentApiResponse.status === 'success') {
          setToastMessage('La destination Tech 4 a bien été mise à jour');
        } else {
          setToastMessage('Une erreur est survenue lors de la mise à jour de la destination Tech 4');
        }
      } else if (enrollmentApiResponse.thunk === 'reenroll') {
        if (enrollmentApiResponse.status === 'success') {
          setToastMessage('L\'opération de réinscription a bien été effectuée');
        } else {
          setToastMessage('Une erreur est survenue lors de l\'opération de réinscription');
        }
      }
      if (enrollmentApiResponse.status === 'success') {
        if ((enrollment._id !== undefined) && (enrollment._id !== null))
          dispatch(fetchEnrollment({ id: enrollment._id }));
      }
      dispatch(resetApiResponse());
      document.getElementById('navbar-toast').style.opacity = 1;
      setTimeout(function() {
        document.getElementById('navbar-toast').style.opacity = 0;
        setTimeout(function() {
          setToastMessage('');
        }, 1000);
      }, 3000);
    }
  }, [enrollmentApiResponse, toastMessage]);
  
  if ((firstname === null) || (lastname === null)) {
    dispatch(fetchMe());
  }

  function toggleMode() {
    dispatch(toggleDarkMode());
  }

  return (
    <nav className="navbar p-0 fixed-top d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <a className="navbar-brand brand-logo-mini" href="/">
          <img src={require('../../assets/images/epitech_logo_mini.png')} alt="Logo Epitech" />
        </a>
      </div>
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <button className="navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
          <Icon path={mdiMenu} size='14' />
        </button>
        <ul className="navbar-nav navbar-nav-left">
          <li className="nav-item" id="navbar-toast">
            {toastMessage}
          </li>
        </ul>
        <ul className="navbar-nav navbar-nav-right">
          <Dropdown alignRight as="li" className="nav-item dropdown d-none d-lg-block">
            <Dropdown.Toggle as="a" className="nav-link cursor-pointer" bsPrefix="cursor-pointer">
              <Icon path={mdiViewGrid} size='20' />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list">
                <h6 className="p-3 mb-0"><Trans>Menu</Trans></h6>
                <Dropdown.Divider />
                <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <Icon path={mdiAccountMultiple} size='24' className='text-primary' />
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1"><Trans>Liste des étudiants</Trans></p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <Icon path={mdiAccountOutline} size='24' className='text-info' />
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1"><Trans>Liste des utilisateurs</Trans></p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <Icon path={mdiKeyVariant} size='24' className='text-danger' />
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1"><Trans>Liste des permissions</Trans></p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <Icon path={mdiServerNetwork} size='24' className='text-warning' />
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1"><Trans>API</Trans></p>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
          <Dropdown alignRight as="li" className="nav-item">
            <Dropdown.Toggle as="a" className="nav-link cursor-pointer" bsPrefix="no-caret">
              <div className="navbar-profile">
                <p className="mb-0 d-none d-sm-block navbar-profile-name"><Trans>{firstname} {lastname}</Trans></p>
                <Icon path={mdiMenuDown} className="d-none d-sm-block" size="16" />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="navbar-dropdown dropdown-menu-right preview-list navbar-profile-dropdown-menu">
              <h6 className="p-3 mb-0"><Trans>Profil</Trans></h6>
              <Dropdown.Divider />
              <Dropdown.Item href="!#" onClick={() => toggleMode()} className="preview-item">
                <a className="dropdown-item preview-item">
                  <label for="themeToggle" className="themeToggle">
                    <span>Light</span>
                    <span className="themeToggle__toggle-wrap">
                      <input
                        type="checkbox"
                        className="themeToggle__toggle"
                        id="themeToggle"
                        role="switch"
                        name="themeToggle"
                        value="dark"
                        checked={darkMode}
                      />
                      <span className="themeToggle__fill"></span>
                      <span className="themeToggle__icon">
                        <span className="themeToggle__icon-part"></span>
                        <span className="themeToggle__icon-part"></span>
                        <span className="themeToggle__icon-part"></span>
                        <span className="themeToggle__icon-part"></span>
                        <span className="themeToggle__icon-part"></span>
                        <span className="themeToggle__icon-part"></span>
                        <span className="themeToggle__icon-part"></span>
                        <span className="themeToggle__icon-part"></span>
                        <span className="themeToggle__icon-part"></span>
                      </span>
                    </span>
                    <span>Dark</span>
                  </label>
                </a>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}  className="preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <Icon path={mdiLogout} size='20' className='text-danger' />
                  </div>
                </div>
                <div className="preview-item-content" onClick={ e => history.push('/logout') }>
                  <p className="preview-subject mb-1"><Trans>Déconnexion</Trans></p>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span className="mdi mdi-format-line-spacing"></span>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;


{/* <nav className="navbar p-0 fixed-top d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo-mini" to="/"><img src={'../../assets/images/logo-mini.svg'} alt="logo" /></Link>
        </div>
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          <button className="navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button>
          <ul className="navbar-nav w-100">
            <li className="nav-item w-100">
              <form className="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
                <input type="text" className="form-control" placeholder="Search products" />
              </form>
            </li>
          </ul>
          <ul className="navbar-nav navbar-nav-right">
            <Dropdown alignRight as="li" className="nav-item d-none d-lg-block">
                <Dropdown.Toggle className="nav-link btn btn-success create-new-button no-caret">
                + <Trans>Create New Project</Trans>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown preview-list create-new-dropdown-menu">
                  <h6 className="p-3 mb-0"><Trans>Projects</Trans></h6>
                  <Dropdown.Divider />
                  <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-file-outline text-primary"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1"><Trans>Software Development</Trans></p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-web text-info"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1"><Trans>UI Development</Trans></p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-layers text-danger"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1"><Trans>Software Testing</Trans></p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <p className="p-3 mb-0 text-center"><Trans>See all projects</Trans></p>
                </Dropdown.Menu>
              </Dropdown>
            <li className="nav-item d-none d-lg-block">
              <a className="nav-link" href="!#" onClick={event => event.preventDefault()}>
                <i className="mdi mdi-view-grid"></i>
              </a>
            </li>
            <Dropdown alignRight as="li" className="nav-item border-left" >
              <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer">
                <i className="mdi mdi-email"></i>
                <span className="count bg-success"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown preview-list">
                  <h6 className="p-3 mb-0"><Trans>Messages</Trans></h6>
                  <Dropdown.Divider />
                  <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <img src={require('../../assets/images/faces/face4.jpg')} alt="profile" className="rounded-circle profile-pic" />
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1"><Trans>Mark send you a message</Trans></p>
                      <p className="text-muted mb-0"> 1 <Trans>Minutes ago</Trans> </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <img src={require('../../assets/images/faces/face2.jpg')} alt="profile" className="rounded-circle profile-pic" />
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1"><Trans>Cregh send you a message</Trans></p>
                      <p className="text-muted mb-0"> 15 <Trans>Minutes ago</Trans> </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <img src={require('../../assets/images/faces/face3.jpg')} alt="profile" className="rounded-circle profile-pic" />
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject ellipsis mb-1"><Trans>Profile picture updated</Trans></p>
                      <p className="text-muted mb-0"> 18 <Trans>Minutes ago</Trans> </p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <p className="p-3 mb-0 text-center">4 <Trans>new messages</Trans></p>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown alignRight as="li" className="nav-item border-left">
              <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer">
                <i className="mdi mdi-bell"></i>
                <span className="count bg-danger"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                <h6 className="p-3 mb-0"><Trans>Notifications</Trans></h6>
                <Dropdown.Divider />
                <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-calendar text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1"><Trans>Event today</Trans></p>
                    <p className="text-muted ellipsis mb-0">
                    <Trans>Just a reminder that you have an event today</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-settings text-danger"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject mb-1"><Trans>Settings</Trans></h6>
                    <p className="text-muted ellipsis mb-0">
                    <Trans>Update dashboard</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-link-variant text-warning"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <h6 className="preview-subject mb-1"><Trans>Launch Admin</Trans></h6>
                    <p className="text-muted ellipsis mb-0">
                    <Trans>New admin wow</Trans>!
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <p className="p-3 mb-0 text-center"><Trans>See all notifications</Trans></p>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown alignRight as="li" className="nav-item">
              <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                <div className="navbar-profile">
                  <img className="img-xs rounded-circle" src={require('../../assets/images/faces/face15.jpg')} alt="profile" />
                  <p className="mb-0 d-none d-sm-block navbar-profile-name"><Trans>Henry Klein</Trans></p>
                  <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                <h6 className="p-3 mb-0"><Trans>Profile</Trans></h6>
                <Dropdown.Divider />
                <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()} className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-settings text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1"><Trans>Settings</Trans></p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()}  className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-logout text-danger"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1"><Trans>Log Out</Trans></p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <p className="p-3 mb-0 text-center"><Trans>Advanced settings</Trans></p>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-format-line-spacing"></span>
          </button>
        </div>
      </nav> */}