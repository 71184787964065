import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
  },
  reducers: {
  },
})

// Action creators are generated for each case reducer function
export const {  } = sidebarSlice.actions

export default sidebarSlice.reducer